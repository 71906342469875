<template>
  <!--
    The view for the Order error logs
  -->
  <div class="installationDetail_OrderErrorLogs">
    <template>
      <div class="card">
        <Portlet
          title="Order Error Logs"
          icon="receipt"
        >
          <template slot="buttons">
            <date-picker
              v-model="date"
              :editable="true"
              :clearable="false"
              type="date"
              lang="en"
              format="DD.MM.YYYY"
              value-type="date"
              :first-day-of-week="1"
              :not-before="notBefore"
              :not-after="notAfter"
              class="mr-3"
            />
            <p class="mt-3 mr-3">
              {{ refreshDate }}
            </p>
            <font-awesome-icon
              :class="['alt-pointer color-primary mr-2', { 'fa-spin' : loading}]"
              icon="sync-alt"
              @click="getOrderErrorLogs()"
            />
          </template>
          <OrderErrorLogsList
            ref="list"
            :installation-id="installationId"
            :date="date"
            @stopLoading="sLoading"
            @setBalanceUrl="setBalanceUrl"
          />
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "InstallationDetailOrderErrorLogs",
  components: {
    OrderErrorLogsList: () => import('@/components/SoftwareComponent/OrderErrorLogsList.vue'),
    DatePicker
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      refreshLogs: null,
      refreshDate: null,
      date: new Date(),
      balanceUrl: null
    };
  },
  metaInfo () {
    return {
      title: 'Order Error Logs'
    };
  },
  computed: {
    notAfter () {
      return this.moment().toDate()
    },
    notBefore () {
      return this.moment().add(-6, 'd').toDate()
    }
  },
  beforeMount () {
      this.refreshLogs = window.setInterval(() => {
        this.getOrderErrorLogs();
      }, 60000 * 3);
    },
    beforeDestroy () {
      window.clearInterval(this.refreshLogs);
    },
  methods: {
    setBalanceUrl (val) {
      this.balanceUrl = val;
    },
    sLoading (date) {
      this.refreshDate = date;
      this.loading = false;
    },
    getOrderErrorLogs () {
      this.loading = true;
      this.$refs.list.getOrderErrorLogs();
    }
  }
};
</script>